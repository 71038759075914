import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import * as styles from "./header.module.scss"


const randomGenerator = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min
}

const Header = () => {
    const data = useStaticQuery(graphql`
        query {
            images: allMarkdownRemark(
                filter: { fileAbsolutePath: { regex: "/_covers/.*.md$/" } }
            ) {
                edges {
                  node {
                    id
                    frontmatter {
                        title
                        image {
                            childImageSharp {
                                gatsbyImageData (
                                    placeholder: TRACED_SVG
                                )
                            }
                            publicURL
                        }
                    }
                  }
                }
              }
        }
    `).images.edges;

    const randomCover = data[randomGenerator(0, data.length - 1)].node.frontmatter;
    const [cover, setCover] = useState(randomCover)


    const refresh = () => {
        setCover(data[randomGenerator(0, data.length - 1)].node.frontmatter);
    }

    return (
        <header className={`text-white text-center ${styles.header}`}>
            <div className={styles.cover}>
                {cover.image.childImageSharp ? (
                    <GatsbyImage image={getImage(cover.image.childImageSharp)} alt="Cover image"/>
                ) : (
                    <video autoPlay loop muted style={{ objectFit: 'cover', width: '100%' }}>
                        <source src={cover.image.publicURL} type="video/mp4" />
                    </video>
                )}
            </div>
            <div className={styles.overlay}/>
            <div className={styles.desc}>
                <span>Photo : {cover.title}</span>
                <button aria-label="Changer la couverture" onClick={refresh} className="btn btn-link"><span className="fa fa-random"/></button>
            </div>
            <div className="container m-auto" >
                <div className="row h-100 flex-column justify-content-evenly align-items-center">
                    <div className="col-md-8 col-lg-7">
                        <h1>Bagad Men Ru</h1>
                        <p>
                            Le <b>Bagad Men Ru</b> est un ensemble musical traditionnel breton basé à <b>Montfort-sur-Meu</b>, à l'ouest de Rennes. <b>Men Ru</b> en breton ou <b>Roch roj</b> en gallo, le bagad a choisi ce nom pour rappeler <b>la pierre rouge</b> typique de notre cité.
                        </p>
                    </div>
                    <div className="col-md-8 col-lg-7">
                        <a href="#actus" className={`btn ${styles.btn}`}>Voir les actualités</a>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
