import React, { useState, useEffect } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

import * as styles from "./navbar.module.scss"
import logo from "../images/logo.svg"


const Navbar = (props) => {
    const data = useStaticQuery(graphql`
        query {
            pages:allMarkdownRemark(
                filter: {frontmatter: {menu: {eq: true}}}
                sort: {fields: frontmatter___order}
            ) {
                edges {
                    node {
                        id
                        frontmatter {
                            title
                        }
                        fields {
                            slug
                        }
                    }
                }
            }
        }
    `).pages.edges;

    const [navbarTheme, setNavbarTheme] = useState(styles.dark);
    const [navbarBackground, setNavbarBackground] = useState(styles.dark);

    useEffect(() => {
        const handleScroll = () => {
            setNavbarTheme(window.pageYOffset === 0 ? styles.dark : styles.light);
            setNavbarBackground(window.pageYOffset === 0 ? (props.transparent ? styles.bgTransparent : styles.bgDark) : styles.bgLight);
        }
            
        window.addEventListener("scroll", handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [props.transparent]);


    const onBlur = (e) => {
        if (document.querySelector(e.target.dataset.bsTarget).classList.contains('show')) {
            e.target.click();
        }
    }

    return (
        <nav className={`navbar fixed-top navbar-expand-md align-items-stretch ${styles.nav} ${navbarTheme} ${navbarBackground}`}>
            <div className="container-fluid p-0">
                <div className={styles.headerWrapper}>
                    <Link className="navbar-brand d-flex" to="/">
                        <img src={logo} alt="" width={30} height={30}/>
                    </Link>
                    <button className={`d-block d-md-none ${styles.navbarToggler}`} type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation"
                            onBlur={onBlur}>
                        <span className={styles.iconBar}></span>
                        <span className={styles.iconBar}></span>
                        <span className={styles.iconBar}></span>
                    </button>
                </div>
                <div className={`collapse navbar-collapse justify-content-end align-items-stretch ${styles.contentWrapper}`}
                     id="navbarSupportedContent">
                    <div className={styles.paddingWrapper}>
                        <h1 className="navbar-brand d-md-none" href="/">Bagad Men Ru</h1>
                        <ul className="navbar-nav mb-2 mb-lg-0 align-items-md-center">
                            <li className="nav-item">
                                <Link to="/" className={`nav-link ${styles.navLink}`} activeClassName={styles.active}>Accueil</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/timeline" className={`nav-link ${styles.navLink}`} activeClassName={styles.active}>Le Bagad</Link>
                            </li>
                            {data.map(edge =>
                                <li key={edge.node.id} className="nav-item">
                                    <Link to={edge.node.fields.slug}
                                        className={`nav-link ${styles.navLink}`}
                                        activeClassName={styles.active}>{edge.node.frontmatter.title}</Link>
                                </li>
                            )}
                            <li className="nav-item">
                                <a href="https://membres.bagadmenru.bzh/" className={`nav-link ${styles.navLink}`} title="Espace membres"><span className="fas fa-lock"/><span className=" d-inline d-md-none"> Espace membres</span></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
