import React from "react";
import { Link } from "gatsby";

import * as styles from './blog-feed.module.scss'
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

const BlogFeed = ({ posts }) => {
    return (
        <div className="container">
            <h1>Actualités</h1>
            <div className="row g-3">
                {posts.map(edge =>
                    <div key={edge.node.id} className="col-12 col-sm-6 col-md-4">
                      <div className={styles.post}>
                        {edge.node.frontmatter.image ?
                            <GatsbyImage alt={edge.node.id} image={getImage(edge.node.frontmatter.image)}/> :
                            <StaticImage alt={edge.node.id} src="../images/placeholder.svg" aspectRatio={1.5}/>
                        }
                        <div className={styles.content}>
                        <h4 className={styles.title}>{edge.node.frontmatter.title}</h4>
                        <span className={styles.metadata}>Le { edge.node.frontmatter.date }</span>
                        { edge.node.frontmatter.excerpt }
                        <div className="mt-4 text-end">
                        <Link to={`/blog${edge.node.fields.slug}`} className="btn btn-sm btn-outline-primary">Lire la suite</Link>
                        </div>
                        </div>
                      </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default BlogFeed
