import React, { useState, useEffect } from "react"
import Helmet from "react-helmet"

import Navbar from "./navbar";
import Footer from "./footer";
import IndexPage from "../pages/index";
import Timeline from "../pages/timeline";


export const Layout = (props) => {
    const [transparent, setTransparent] = useState(true);

    useEffect(() => {
        setTransparent(props.children.type === IndexPage || props.children.type === Timeline)
    }, [props.children.type]);

    return (
        <div style={{paddingTop: transparent ? 0 : "82px"}}>
            <Helmet>
                <title>Bagad Men Ru</title>
            </Helmet>
            <Navbar transparent={transparent}/>
            {props.children}
            <Footer/>
        </div>
    )
}
