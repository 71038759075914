import React from "react";

import * as styles from './youtube-feed.module.scss'
import {GatsbyImage, getImage} from "gatsby-plugin-image";


const YoutubeFeed = ({videos}) => {
    return (
        <div className="container">
            <h1>Nos Vidéos Youtube</h1>
            <div className="row gallery">
                {videos.map(edge =>
                    <a href={`https://www.youtube.com/embed/${edge.node.videoId}?autoplay=true`}
                        className="col-12 col-md-6 col-xl-4 g-4"
                        key={edge.node.id}
                        title={edge.node.title}>
                        <div className={styles.wrapper}>
                            <GatsbyImage alt={edge.node.title} image={getImage(edge.node.imageSharp)} className={`${styles.image} rounded`}/>
                            <div className={styles.content}>
                                <h6>{edge.node.publishedAt}</h6>
                                <h3>{edge.node.title}</h3>
                            </div>
                        </div>
                    </a>
                )}
            </div>
        </div>
    )
}

export default YoutubeFeed
