import React, { useState } from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import * as styles from "./timeline.module.scss"

const Timeline = ({ data }) => {

  const [index, setIndex] = useState(0);

  const item = data.allMarkdownRemark.edges[index]
  
  return (
    <div className="text-white">
      <div className={styles.event}>
        <div className={styles.cover}>
          {item.node.frontmatter.image && <GatsbyImage image={getImage(item.node.frontmatter.image.childImageSharp)} alt="Cover image"/>}
        </div>
        <div className={styles.overlay}/>
        <div className="container" >
          <div className="h-100 d-flex align-items-center">
            <div className={styles.timeline}>
              <ul>
                {data.allMarkdownRemark.edges.map((item, i) => 
                <li key={item.node.id}><a href={`#event-${i}`} onClick={() => setIndex(i)} className={`${styles.date} ${i === index ? styles.active : null}`}>{item.node.frontmatter.year}</a></li>
                )}
              </ul>
            </div>
            <div className={styles.content}>
              <h1 className={styles.date}>{item.node.frontmatter.year}</h1>
              <p dangerouslySetInnerHTML={{__html: item.node.html}}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
query{
  allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/_timeline/.*.md$/"}}
    sort: {fields: frontmatter___year, order: ASC}
  ) {
    edges {
      node {
        id
        html
        frontmatter {
          year
          image {
            childImageSharp {
              gatsbyImageData (
                  placeholder: TRACED_SVG
              )
            }
          }
        }
      }
    }
  }
}
`

export default Timeline
