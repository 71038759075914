import React from "react"

import sonerion from "../images/sonerion.png"
import montfort from "../images/montfort2.png"
import frise from "../images/friseBlanc.svg"
import * as styles from "./footer.module.scss"

const Footer = () => {
    return (
        <>
            <footer className={styles.footer}>
                <div className="container">
                    <div className={`text-center ${styles.socials}`}>
                        <h3>Suivez-nous sur les réseaux</h3>
                        <div>
                            <a href="mailto:contact@bagadmenru.bzh" className={styles.socialLink}>
                                <span className="fas fa-at"/>
                            </a>
                            <a href="https://www.facebook.com/BagadMenRu" className={styles.socialLink}>
                                <span className="fab fa-facebook"/>
                            </a>
                            <a href="https://www.instagram.com/bagadmenru" className={styles.socialLink}>
                                <span className="fab fa-instagram"/>
                            </a>
                            <a href="https://www.youtube.com/channel/UCXeTfDjXSVWYNIbC4NQQ9SQ" className={styles.socialLink}>
                                <span className="fab fa-youtube"/>
                            </a>
                        </div>
                    </div>
                    <div className={`row ${styles.images}`}>
                        <div className="col-md-4">
                            <a href="https://sonerion.bzh"><img src={sonerion} alt="Sonerion"/></a>                                
                        </div>
                        <div className="col-md-4">
                                <a href="https://www.montfort-sur-meu.bzh"><img src={montfort} alt="Montfort"/></a>
                        </div>
                        <div className="col-md-4">
                            <img src={frise} alt=""/>
                        </div>
                    </div>
                </div>
            </footer>
            <div className={styles.copyright}>
                <div className="container">
                    <div className="row align-items-center text-center text-md-left">
                        <div className="col-md-auto me-auto">
                            <div className={styles.me}>Made with <span className="fa fa-heart"></span> by Mael G.</div>
                        </div>
                        <div className="col-md-auto">
                            <a href="https://membres.bagadmenru.bzh/" className={`mt-5 m-md-0 ${styles.btnSocial}`}>
                                <span className="fa fa-key"/> Espace membres
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer
