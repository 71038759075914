import React from "react";

import "bootstrap/js/dist/collapse";
import "./src/styles/styles.scss";
import '@fortawesome/fontawesome-free/css/all.min.css'
import "simple-lightbox/dist/simpleLightbox.min.css"

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Layout } from "./src/components/layout";


export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY} language="fr">
      {element}
    </GoogleReCaptchaProvider>
  )
}


// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}
