import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"

import * as styles from "./index.module.scss"

import Header from "../components/header";
import YoutubeFeed from "../components/youtube-feed";
import InstagramFeed from "../components/instagram-feed";
import BlogFeed from "../components/blog-feed";

import SimpleLightbox from 'simple-lightbox';



const IndexPage = ({ data }) => {

  useEffect(() => {
    if (typeof window !== 'undefined') {
      new SimpleLightbox({elements: '.gallery a'});
    }
  }, []);

  return (
    <div>
      <Header/>
      <section id="actus" className={styles.section}>
        <BlogFeed posts={data.blog.edges}/>
        <div className={styles.more}>
          <Link to="/blog" className="btn btn-outline-primary">Anciennes actualités</Link>
        </div>
      </section>
      <section className={styles.section}>
        <YoutubeFeed videos={data.youtube.edges}/>
        <div className={styles.more}>
          <a href="https://www.youtube.com/channel/UCXeTfDjXSVWYNIbC4NQQ9SQ" className="btn btn-outline-primary"><span className="fab fa-youtube"></span> Plus de vidéos</a>
        </div>
      </section>
      <section className={styles.section}>
        <InstagramFeed posts={data.instagram.edges}/>
        <div className={styles.more}>
          <a href="https://www.instagram.com/bagadmenru" className="btn btn-outline-primary"><span className="fab fa-instagram"></span> Suivez nous</a>
        </div>
      </section>
    </div>
  )
}

export const query = graphql`
query {
    blog: allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/_posts/.*.md$/"}}
      limit: 3
      sort: {fields: frontmatter___date, order: DESC}
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            date(formatString: "D MMMM YYYY", locale: "FR-fr")
            excerpt
            image {
              id
              childImageSharp {
                gatsbyImageData (
                  aspectRatio: 1.5
                )
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    youtube: allYoutubePlaylistItem(limit: 9, sort: {order: DESC, fields: publishedAt})  {
      edges {
        node {
          id
          title
          publishedAt(formatString: "LL", locale: "fr")
          videoId
          imageSharp {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 1.77
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
    instagram: allInstagramPost(limit: 12, sort: {order: DESC, fields: publishedAt})  {
      edges {
        node {
          id
          caption
          mediaUrl
          imageSharp {
            publicURL
            childImageSharp {
              gatsbyImageData(aspectRatio: 1, placeholder: DOMINANT_COLOR)
            }
          }
          publishedAt(formatString: "LL", locale: "fr")
        }
      }
    }
  }
`;


export default IndexPage
