import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const InstagramFeed = (props) => {
    return (
        <div className="container">
            <h1>Galerie Instagram</h1>
            <div className="row gallery">
                {props.posts.map(edge =>
                    <div className="col-6 col-md-3 g-4" key={edge.node.id}>
                        <div>
                            <a href={edge.node.imageSharp.publicURL} title={edge.node.caption}>
                                <GatsbyImage alt={edge.node.caption} image={getImage(edge.node.imageSharp)} className="rounded"/>
                            </a>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default InstagramFeed
